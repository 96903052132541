import "./Success.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";

import Footer from "../Components/Footer";

export default function Success() {

  return (
    <div>
      <div className="Hero4">
        <a className="HeroHeader2">Thank you for support the Florida Rocket Lab!</a>
        <a className="HeroText2">
          Keep an eye out for a receipt and shipping updates in your email.
        </a>
      </div>        
      <Footer></Footer>
    </div>
  );
}
