
import './Homepage/Homepage.css'
import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles/";
import Theme from "./Theme";
import Homepage from "./Homepage";
import About from "./About";
import Navbar from "./Components/Navbar";
import Sponsors from "./sponsors";
import Projects from "./Projects";
import OurTeam from "./OurTeam"
import Resources from "./Resources";
import Shop from "./Shop/Shop";
import TheFRLTee from "./Shop/TheFRLTee";
import Success from "./Shop/Success";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from 'oidc-client-ts';

const oidcConfig = {
  authority: "https://accounts.liquidprop.org/application/o/lpdt-website",
  client_id: "412dd7e96d460206ac2fc07ea0cbe7f4529e081b",
};

function App() {
  return (
    <AuthProvider {...oidcConfig} userStore={new WebStorageStateStore({ store: localStorage })}>
      <ThemeProvider theme={Theme}>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <div>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Homepage />}></Route>
            <Route path="/" element={<Homepage />}></Route>
            <Route path="/shop" element={<Shop/>}></Route>
            <Route path="/shop/the-frl-tee" element={<TheFRLTee/>}></Route>
            <Route path="/shop/success" element={<Success/>}></Route>
            <Route path="/About" element={<About />}></Route>
            <Route path="/Projects" element={<Projects />}></Route>
            <Route path="/sponsors" element={<Sponsors/>}></Route>
            <Route path="/ourteam" element={<OurTeam/>}></Route>
            <Route path="/resources" element={<Resources/>}></Route>

            <Route path='/donate' component={() => {
              window.location.href = 'https://www.uff.ufl.edu/giving-opportunities/027281-mae-liquid-propulsion/';
              return null;
            }} />

          </Routes>
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;