import * as React from 'react';
// import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Sidebar from '../Sidebar';
import "./Navbar.css"
import { /*TbApps, TbLogout,*/ TbMenu2/*, TbUser*/ } from 'react-icons/tb';
// import whiteLogo from "./LPLogo_White.svg"
import blackLogo from "./LPLogo_Black.svg"
// import { useAuth } from 'react-oidc-context';
// import gravatarUrl from 'gravatar-url';
// import { Avatar, CircularProgress, Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';

// const sx1 = {
//   backgroundColor: "transparent",
//   border: "2px solid white",
//   borderRadius: 8,
//   align: "center",
//   width: "100px",
//   fontFamily: 'Montserrat',
//   fontWeight: 'bold',
//   letterSpacing: '1px',
//   '&:hover': {
//     backgroundColor: '#4484b4',
//     color: 'white',
//   },
//   transition: "0.2s ease-in-out"
// }

// const sx2 = {
//   backgroundColor: "transparent",
//   border: "2px solid black",
//   color: "black",
//   borderRadius: 8,
//   align: "center",
//   width: "100px",
//   fontFamily: 'Montserrat',
//   fontWeight: 'bold',
//   letterSpacing: '1px',
//   '&:hover': {
//     backgroundColor: '#4484b4',
//     color: 'white',
//   }
// }

export default function Navbar() {
  const [TPNav, setTPNav] = useState("NavbarTransparent");
  const [WhiteLinks, setWhiteLinks] = useState("NavbarLinkTransparent");
  // const [TPButton, setTPButton] = useState(sx1);
  const [sidebar, setSidebar] = useState("SidebarContainerHidden")
  // const [anchorEl, setAnchorEl] = useState(null);
  const [logoColor, setLogoColor] = useState("FilterWhite");
  const [iconColor, setIconColor] = useState("NavbarIconTextTransparent")
  // const open = Boolean(anchorEl);
  // const auth = useAuth();





  // if (auth.user != null) {
  //   console.log(auth.user);
  // }


  //for named expressions, we treat arrow expressions like variables.
  const toggleNav = () => {

    if (window.scrollY >= 10) {
      setTPNav("Navbar");
      setWhiteLinks("NavbarLink");
      setIconColor("NavbarIconText")
      // setTPButton(sx2);
      setLogoColor("FilterBlack");
    }
    else {
      setTPNav("NavbarTransparent");
      setWhiteLinks("NavbarLinkTransparent");
      setIconColor("NavbarIconTextTransparent")
      // setTPButton(sx1);
      setLogoColor("FilterWhite");
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', toggleNav)
  }, [])

  const handleClick = name => {
    setSidebar(name);
  };
  //const scrollPosition = useScrollPosition();
  //const [drawerOpen, setDrawerOpen] = useState(false);
  //const [backgroundT, setbackgroundT] = useState("transparent");

  return (
    <div>
      <Sidebar className={sidebar} handleClick={handleClick}></Sidebar>
      <nav className={TPNav}>
        <div className="NavbarContainer">
          <Link className="NavbarLogo" to="/">
            <img className ={logoColor} src={blackLogo} width="60px" height="60px"></img>
          </Link>


          <div className="NavbarMobileIcon"
            onClick={() => {
              handleClick("SidebarContainerNormal");
            }}
          >
            <TbMenu2 className={iconColor} />
          </div>
          <div className="NavbarMenu">
            <div className="NavbarItem">
              <Link className={WhiteLinks} to="/">Home</Link>
            </div>
            <div className="NavbarItem">
              <Link className={WhiteLinks} to="shop">Shop</Link>
            </div>
            <div className="NavbarItem">
              <Link className={WhiteLinks} to="about">About</Link>
            </div>
            <div className="NavbarItem">
              <Link className={WhiteLinks} to="projects">Projects</Link>
            </div>
            <div className="NavbarItem">
              <Link className={WhiteLinks} to="sponsors">Sponsors</Link>
            </div>
            <div className="NavbarItem">
              <Link className={WhiteLinks} to="ourTeam">Our Team</Link>
            </div>

          </div>

          <div className="NavbarButtonContainer">
            <Link className={WhiteLinks} to="resources">Resources</Link>
          </div>

        </div>
      </nav>
    </div>
  );

}


