import "./Shop.css";
import React from "react";

import TheFRLTee from "./Images/the-frl-tee-ghost.png"


import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

//import { useState } from "react"
export default function OurTeam() {
  return (
    <div>
      <div className="Hero4">
        <a className="HeroHeader2">Shop</a>
        <a className="HeroText2">
          Help support our team while getting some awesome merchandise to show
          off to your friends!
        </a>
      </div>
      <div className="ContentBox">
        <div className="TeamContainer">
          <Link to="/shop/the-frl-tee">
            <div className="TeamContent">
              <img
                src={
                    TheFRLTee
                }
                className="TeamPhoto"
              ></img>
              <p className="TeamName">The FRL Tee</p>
              <p className="TeamHeader">$26.99</p>
            </div>
          </Link>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
