import "./TheFRLTee.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";

import TheFRLTee from "./Images/the-frl-tee-ghost.png";
import TheFRLTeeBack from "./Images/the-frl-tee-ghost-back.png";
import Man from "./Images/man.png";
import Woman from "./Images/woman.png";




import Footer from "../Components/Footer";
import { Carousel } from "react-responsive-carousel";
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  TextField,
  Button,
  Container
} from "@mui/material";
import { Link } from "react-router-dom";

export default function Shop() {
  // State to store the selected size
  const [selectedSize, setSelectedSize] = React.useState("M");
  const [quantity, setQuantity] = React.useState(1);

  // Handler for when the user selects a size
  const handleChange = (event) => {
    setSelectedSize(event.target.value);
  };

  return (
    <div>
      <div className="HeroShop">
        <a className="HeroHeader2">The FRL Tee</a>
        <a className="HeroText2">
          The ultimate T-Shirt to assert your dedication to FRL.
        </a>
      </div>
      <Container>
        <Grid container spacing={2} columns={{ xs: 6, sm: 6, md: 12 }}>
          <Grid item xs={6}>
            <Carousel>
              <div>
                <img src={TheFRLTee} />
              </div>
              <div>
                <img src={TheFRLTeeBack} />
              </div>
              <div>
                <img src={Man} />
              </div>
              <div>
                <img src={Woman} />
              </div>
            </Carousel>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" style={{paddingTop: "30px"}}>Size: </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                value={selectedSize}
                onChange={handleChange}
                row // Display sizes horizontally
              >
                {/* Size options */}
                <FormControlLabel value="S" control={<Radio />} label="S" />
                <FormControlLabel value="M" control={<Radio />} label="M" />
                <FormControlLabel value="L" control={<Radio />} label="L" />
                <FormControlLabel value="XL" control={<Radio />} label="XL" />
                <FormControlLabel value="2XL" control={<Radio />} label="2XL" />
                <FormControlLabel value="3XL" control={<Radio />} label="3XL" />
              </RadioGroup>
            </FormControl>
            <Typography variant="h5" style={{paddingBottom: "15px"}}>Quantity: </Typography>
            <TextField
              type={"number"}
              value={quantity}
              onChange={(event) => {
                if (event.target.value > 0) {
                  setQuantity(event.target.value);
                }
              }}
            />
            <Typography variant="h5" style={{ paddingTop: "40px", paddingBottom: "25px" }}>
              Total: ${quantity * 26.99}
            </Typography>
            <Link to={"https://frl-ordering.macdonnell-chase.workers.dev/frl-tee?quantity=" + quantity + "&size=" + selectedSize}>
                <Button type="submit" variant="contained" color="info" fullWidth={true} style={{marginTop: "10px", marginBottom: "10px"}}>Order</Button>
            </Link>
            <Typography variant="p">
              Tax and Shipping is included in the &quot;Total&quot; price. Shipping is only available to the United States.
            </Typography>
            <div style={{ marginBottom: "20px"}}></div>
          </Grid>
        </Grid>
      </Container>
      <Footer></Footer>
    </div>
  );
}
