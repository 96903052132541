import React from 'react';
import "./Sidebar.css";
import { TbX } from 'react-icons/tb';
import { Link } from 'react-router-dom';
//import { Divider } from '@mui/material';

/* eslint-disable */
export default function Sidebar(props) {


    return (
        <div className={props.className}>

            <div className="SidebarIcon"
                onClick={event => props.handleClick("SidebarContainerHidden")}>
                <TbX className='SidebarIconText' />
            </div>
            <div className="SidebarWrapper">
                <div className="SidebarMenu">

                    <Link className="SidebarLink" to="/" onClick={event => props.handleClick("SidebarContainerHidden")}>
                        Home
                    </Link>

                    <Link className="SidebarLink" to="/shop" onClick={event => props.handleClick("SidebarContainerHidden")}>
                        Shop
                    </Link>

                    <Link className="SidebarLink" to="/about" onClick={event => props.handleClick("SidebarContainerHidden")}>
                        About
                    </Link>

                    <Link className="SidebarLink" to="/projects" onClick={event => props.handleClick("SidebarContainerHidden")}>
                        Projects
                    </Link>

                    <Link className="SidebarLink" to="/sponsors" onClick={event => props.handleClick("SidebarContainerHidden")}>
                        Sponsors
                    </Link>

                    <Link className="SidebarLink" style={{ paddingTop: "25px"}} to="/ourteam" onClick={event => props.handleClick("SidebarContainerHidden")}>
                        Our Team
                    </Link>

                </div>
            </div>
        </div>
    );
}

/* eslint-enable */